//https://accelerometerdemo.netlify.app/
//https://developer.mozilla.org/en-US/docs/Web/API/Touch_events


// window.addEventListener("pageshow", (event) => {
//   if (event.persisted) {
//     alert("The page was cached by the browser");
//   } else {
//     alert("The page was NOT cached by the browser");
//   }
// });




let lastScrollTop = 0;
let scrollSpeed = 0;
let scrollDirection = '';
let running = false;
const element = document.body;
let to = false;
const toSpeed = 250;
var timer;
import * as bootstrap from 'bootstrap'
//import Alert from 'bootstrap/js/dist/alert';
//import { Tooltip, Toast, Popover } from 'bootstrap';

function register_tooltips() {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}

function calculateScroll() {
  const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

  if (currentScrollTop > lastScrollTop) {
    scrollDirection = 'down';
  } else if (currentScrollTop < lastScrollTop) {
    scrollDirection = 'up';
  }

  scrollSpeed = Math.abs(currentScrollTop - lastScrollTop);
  lastScrollTop = currentScrollTop;

  // Output the results (you can modify this part according to your needs)
  //console.clear();
  console.log('Scroll Speed:', scrollSpeed);
  console.log('Scroll Direction:', scrollDirection);
  rerunAnimation(scrollSpeed);
}
document.addEventListener("DOMContentLoaded", function () {
  register_tooltips();
  //alert('yolo!');
});
//document.touches
const wndw = window;
const isMobile = /mobile|tablet/i.test(navigator.userAgent);
//alert(isMobile);
if (isMobile) {
  wndw.addEventListener('touchmove', function () {

    if (timer) {
      wndw.clearTimeout(timer);
    }
    timer = wndw.setTimeout(function () {
      // actual callback
      console.log("Firing!");
      calculateScroll();
    }, 20);
  }, { passive: false });
} else {
  wndw.addEventListener('scroll', function () {

    if (timer) {
      wndw.clearTimeout(timer);
    }
    timer = wndw.setTimeout(function () {
      // actual callback
      console.log("Firing!");
      calculateScroll();
    }, 20);
  }, { passive: false });
}

// Attach the calculateScroll function to the scroll event
// window.addEventListener('scroll', calculateScroll);
// window.addEventListener('resize', rerunAnimation);




function rerunAnimation(scrollSpeed) {
  // -> removing the class
 
  AniClass = "run-animation-sm";
  var AniClass = null;
  if(scrollSpeed < 17) {
    AniClass = "run-animation-sm";
    
    console.log('SM');
   
  } else if (scrollSpeed > 30){
    AniClass = "run-animation-md";
    //running = true;
    console.log('MD:' + AniClass);
   
  }
  //overwrite all with sm
  AniClass = "run-animation-sm";
  // else if (scrollSpeed > 140) {
  //   AniClass = "run-animation-lg";
  //   //running = true;
  //   console.log('LG');
  // }

  
  
  console.log('running:'+running);

  if (running == false) {
    element.classList.remove("run-animation", "run-animation-sm", "run-animation-md");

    // -> triggering reflow /* The actual magic */
    // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
    // Oops! This won't work in strict mode. Thanks Felis Phasma!
    // element.offsetWidth = element.offsetWidth;
    // Do this instead:
    void element.offsetWidth;
    // -> and re-adding the class
    element.classList.add(AniClass);
    console.log(AniClass);
    running = true;
    var st = setTimeout(function(){
      running = false;
      //alert(running);
    }, 1400);
  }
}

document.onkeydown = function (e) {
  if (e.key == '∑') {
    element.classList.add('run-animation-sm');
    var st1 = setTimeout(function () {
      element.classList.remove('run-animation-sm');
    },2000);
    e.preventDefault();
    //return;
  }
  if (e.key == 'œ') {
    element.classList.add('run-animation-md');
    var st1 = setTimeout(function () {
      element.classList.remove('run-animation-md');
    }, 2000);
    e.preventDefault();
    //return;
  }
};

// reset the transition by...
// element.addEventListener("click", function (e) {
//   e.preventDefault;
//   rerunAnimation();
// }, false);







console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 